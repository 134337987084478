import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">AR1VU</a>
          </div>
          
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>403 Forbidden</h1>
            <p>The website you are trying to visit has been banned on your device.</p>
          </div>

        </div>
        <div className="footer">
          <span>If you believe this is an error, please contact your device admin</span>
        </div>
      </div>
    );
  }
}

export default App;